<template>
  <v-dialog
    v-model="show_bid_box"
    persistent
    height="auto"
    width="1280"
    transition="slide-fade"
    hide-overlay
    light
  >
    <v-row no-gutters dense>
      <v-card min-height="100%" min-width="100%" class="my-card d-flex">
        <v-row no-gutters dense class="d-flex flex-column">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row no-gutters dense class="d-flex">
              <v-col cols="12">
                <p class="heading-sf20 pb-4">User Profile</p>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <app-text-box
                  v-model="user_info.fullname"
                  label="Fullname"
                  placeholder="Fullname"
                  :rules="fullnameRules"
                ></app-text-box>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <v-row class="pb-3" dense>
                  <v-col cols="12" class="pt-0">
                    <label>Email</label>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      single-line
                      :readonly="true"
                      outlined
                      dense
                      height="48"
                      color="#CACEDA"
                      type="email"
                      v-model="user_info.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <app-phone-number
                  v-model="user_info.phone"
                  label="Phone"
                  placeholder="Enter contact number"
                  :rules="phoneRules"
                ></app-phone-number>
              </v-col>
              <v-col  v-if="this.current_router === '/contractors'" cols class="col-md-6 col-sm-12 px-2">
                <v-row class="pb-3" dense>
                  <v-col cols="12" class="pt-0">
                    <label>Subscription Id</label>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      single-line
                      outlined
                      dense
                      height="48"
                      color="#CACEDA"
                      type="text"
                      v-model="user_info.subscription_id"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
               <v-col v-if="this.current_router === '/contractors'" cols class="col-md-6 col-sm-12 px-2">
                <v-row class="pb-3" dense>
                  <v-col cols="12" class="pt-0">
                    <label>Team Users</label>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      single-line
                      outlined
                      dense
                      height="48"
                      color="#CACEDA"
                      type="number"
                      v-model="user_info.team_users"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols class="col-sm-12 px-2">
                <p class="heading-sf15 ma-0">Status</p>
                <v-radio-group v-model="user_info.status" class="ma-0" row>
                  <v-radio label="Active" color="info" value="A"></v-radio>
                  <v-radio
                    label="In Active"
                    disabled
                    color="info"
                    value="I"
                  ></v-radio>
                  <v-radio label="Suspend" color="info" value="S"></v-radio>
                  <v-radio label="Delete" color="info" value="D"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
          <v-row no-gutters dense align="stretch" class="d-flex">
            <v-col
              v-if="$store.state.auth.error != ''"
              class="d-flex align-center mt-auto p-0 custom-error"
              cols="12"
              >{{ this.$store.state.auth.error }}</v-col
            >
            <v-col cols="12" class="d-flex justify-end mt-auto pt-4">
              <v-btn
                v-if="this.current_router === '/distributors'"
                class="my-outline-btn mr-4"
                id="my_elevation"
                @click="companyView()"
                depressed
                >Authorize User & Company</v-btn
              >
              <v-btn
                class="my-outline-btn mr-auto"
                id="my_elevation"
                @click="sendResetPassword()"
                depressed
                >Reset Password</v-btn
              >
              <v-btn
                class="my-outline-btn mr-4"
                id="my_elevation"
                @click="show_bid_box = false"
                depressed
                >Cancel</v-btn
              >
              <v-btn
                class="my-auto-btn"
                id="my_elevation"
                @click="userEdit()"
                :disabled="!valid"
                depressed
                >Save changes</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-card>
      <app-company-view ref="companyview"></app-company-view>
    </v-row>
  </v-dialog>
</template>

<script>
import { distributorUpdate } from "../../services/distributorservice";
import { contractorUpdate } from "../../services/contractorservice";
import { userPasswordReset } from "../../services/authservices";
export default {
  components: {
    AppTextBox: () => import("../../mycomponents/MyTextBox"),
    AppPhoneNumber: () => import("../../mycomponents/MyPhoneNumber"),
    AppUserEmail: () => import("../../mycomponents/UserEmail"),
    AppCompanyView: () => import("../common/CompanyView"),
  },

  data() {
    return {
      show_bid_box: false,
      valid: true,
      current_router: "",
      user_info: {
        id: null,
        fullname: null,
        email: null,
        phone: null,
        status: null,
        subscription_id: null,
        team_users: null,
      },
      fullnameRules: [
        (v) => !!v || "Fullname is required",
        (v) => /^[A-Za-z0-9_ ]+$/.test(v) || "Input alphabet character only",
      ],
      phoneRules: [
        (v) =>
          v && v.length > 0
            ? (v && v.length >= 14 && v.length <= 14) ||
              "Phone number must be 10 digit"
            : !v,
      ],
    };
  },

  methods: {
    async openUserEdit(id) {
      Object.assign(this.$data, this.$options.data());
      this.current_router = this.$router.currentRoute.path;
      if (this.current_router === "/contractors") {
        this.user_info = JSON.parse(
          JSON.stringify(this.$store.getters.getContractorById(id))
        );
      } else if (this.current_router === "/distributors") {
        this.user_info = JSON.parse(
          JSON.stringify(this.$store.getters.getDistributorById(id))
        );
      }

      this.show_bid_box = true;
    },

    companyView() {
      this.$refs.companyview.openCompanyView(this.user_info.id);
    },

    async sendResetPassword() {
      this.$store.commit("errorMessage", "");

      let alert = {
        show: true,
        message: "User password reset link sent successfully",
        type: "success",
      };
      let ob = {
        email: this.user_info.email,
      };

      let data = await userPasswordReset(ob);
      if (data.status === 200) {
        this.$store.commit("errorMessage", "");
        this.$store.commit("showAlert", alert);
      }
    },

    async userEdit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("errorMessage", "");

        let alert = {
          show: true,
          message: "User profile update successfully",
          type: "success",
        };

        let user = {
          fullname: this.user_info.fullname,
          phone: this.user_info.phone,
          status: this.user_info.status,
        };

        if (this.current_router === "/contractors") {
          user.subscription_id = this.user_info.subscription_id;
          user.team_users = this.user_info.team_users;
          let data = await contractorUpdate(this.user_info.id, user);
          if (data.data) {
            this.$store.dispatch("contractorUpdate", this.user_info);
            this.$store.commit("showAlert", alert);
            this.show_bid_box = false;
          }
        } else if (this.current_router === "/distributors") {
          let data = await distributorUpdate(this.user_info.id, user);
          if (data.data) {
            this.$store.dispatch("distributorUpdate", this.user_info);
            this.$store.commit("showAlert", alert);
            this.show_bid_box = false;
          }
        }
      }
    },
  },
};
</script>